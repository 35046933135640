<template>
  <div />
</template>

<script>
export default {
  mounted() {
    if (!this.$auth.loading) {
      this.$auth.loginWithRedirect()
    }
  },
}
</script>

<style>

</style>
